import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Formik, Form, Field, ErrorMessage } from "formik"
import "./contact-form.scss"
import DualColumns from "../dual-columns/dual-columns"
import kit from "../../docs/USLPA_Media_Kit.pdf"
import reps from "../../docs/USLPA_Team_Reps_2021.pdf"

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const ContactForm = props => {
  const data = useStaticQuery(graphql`
    query {
      img: file(relativePath: { eq: "bargaining-committee.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div className="container">
      <DualColumns>
        <div className="contact-form-wrapper">
          <div>
            <h3>
              Question? Media Request? Concern? Please let us know how we can
              help.
            </h3>
            <Formik
              initialValues={{
                name: "",
                email: "",
                message: "",
              }}
              onSubmit={(values, actions) => {
                fetch("/", {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                  },
                  body: encode({ "form-name": "contact-form", ...values }),
                })
                  .then(() => {
                    alert("Success! Message Sent")
                    actions.resetForm()
                  })
                  .catch(() => {
                    alert("Error")
                  })
                  .finally(() => actions.setSubmitting(false))
              }}
              validate={values => {
                const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
                const errors = {}
                if (!values.name) {
                  errors.name = " - Required"
                }
                if (!values.email || !emailRegex.test(values.email)) {
                  errors.email = " - Valid Email Required"
                }
                if (!values.message) {
                  errors.message = " - Required"
                }
                return errors
              }}
            >
              {() => (
                <Form
                  name="contact-form"
                  data-netlify={true}
                  method="post"
                  netlify-honeypot="bot-field"
                  className="contact-form"
                >
                  <input type="hidden" name="bot-field" />

                  <div className="input-wrapper">
                    <label htmlFor="name">Name</label>
                    <span className="error-message">
                      <ErrorMessage name="name" />
                    </span>
                    <Field name="name" />
                  </div>
                  <div className="input-wrapper">
                    <label htmlFor="email">Email</label>
                    <span className="error-message">
                      <ErrorMessage name="email" />
                    </span>
                    <Field name="email" />
                  </div>
                  <div className="input-wrapper">
                    <label htmlFor="message">Message</label>
                    <span className="error-message">
                      <ErrorMessage name="message" />
                    </span>
                    <Field name="message" component="textarea" />
                  </div>
                  <button type="submit">Send</button>
                </Form>
              )}
            </Formik>
          </div>
          <div className="contact-button-wrapper">
            <a href={kit} target="_blank" rel="noreferrer" className="button">
              Media Kit
            </a>
            <a href={reps} target="_blank" rel="noreferrer" className="button">
              Who's Your Rep?
            </a>
          </div>
        </div>
        <Img fluid={data.img.childImageSharp.fluid} className="cta-image" />
      </DualColumns>
    </div>
  )
}

export default ContactForm
