import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import ContactForm from '../components/contact-form/contact-form'

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="USLPA - Contact Us" />
      <h1>Contact Us</h1>
      <ContactForm />
    </Layout>
  )
}

export default IndexPage
